import React from 'react'
//

import '@fontsource/fira-sans/400.css'
import '@fontsource/fira-sans/400-italic.css'
import '@fontsource/fira-sans/500.css'
import '@fontsource/fira-sans/600.css'

import '@fontsource/quicksand/400.css'
import '@fontsource/quicksand/500.css'
import '@fontsource/quicksand/600.css'

import '@fontsource/roboto/400.css'
import '@fontsource/roboto/700.css'

import '@fontsource/catamaran/400.css'
import '@fontsource/catamaran/700.css'


import '../../styles/tailwind.css'

import Header from '~components/header'
import Footer from '~components/footer'
import Cookies from '~components/cookies'

import ParagraphAnnouncement from '~components/paragraphs/announcement'
import ParagraphMiniGDPR from '~components/paragraphs/announcement'

export const PageLayoutContext = React.createContext({})

const Layout = ({
  language,
  menus,
  disableLangSwitcher = false,
  announcement = {},
  miniGdprAnnouncement = {},
  splashHeader = false,
  noFooter = false,
  noHeader = false,
  cookiesInfo = { categories: [] },
  fieldCss,
  groupCss,
  nodeCss,
  pageID = null,
  children
}) => {
  const pageContext = { disableLangSwitcher }

  // remove leading zero from uuid. cant select having a leading zero
  const uuid = pageID?.replace(/^0+/, '')

  return (
    <>
      <PageLayoutContext.Provider value={pageContext}>
        <style>{ groupCss }</style>
        <style>{ nodeCss }</style>
        <div class="flex flex-col" style={{minHeight:"100vh"}} id={uuid}>
          {announcement ? (
            <ParagraphAnnouncement announcement={announcement?.entity} />
          ) : null}
          {!noHeader ? (
            <Header
              navigation={menus?.main || []}
              secondary={menus?.secondary || []}
              language={language}
              disableLangSwitcher={disableLangSwitcher}
              splashHeader={splashHeader}
            />
          ) : null}
          <main style={{flex: 1}}>{children}</main>
          {!noFooter && <Footer navigation={menus?.footer || []} legal={menus?.legal || []} />}
          {cookiesInfo?.fieldGdprCookies?.length ? <Cookies {...cookiesInfo} /> : null}
          <ParagraphMiniGDPR announcement={miniGdprAnnouncement?.entity} />
        </div>
      </PageLayoutContext.Provider>
    </>
  )
}

export default Layout
