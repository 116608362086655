import React from 'react'
import htmr from 'htmr'
import { Link } from 'gatsby'
import { styled } from 'twin.macro'
//
import { tableTransform } from '~storybook/tables'
import { SiteDataContext } from '~components/layout/wrappers'

const SITE_URL = process.env.SITE_URL || ''

const StyledContainer = styled.div`
  .text-align-left {
    text-align: left;
  }
  .text-align-center {
    text-align: center;
  }
  .text-align-right {
    text-align: right;
  }
`

const renderLink = ({ href, children, style }) => {
  const props = {}
  const siteData = React.useContext(SiteDataContext)
  const projectCode = siteData?.group?.fieldGroupProjectCode || ''

  // If in Drupal no href was entered, the href would become a true boolean
  // replace with empty string to link to itself, otherwise we get a 'href.replace is not a function' error.
  if(href === true) {
    href = ""
  }

  const to = href?.replace(SITE_URL, '').replace(`/${projectCode}`, '')

  if (to?.startsWith('/')) {
    return <Link to={to} css={style}>{children}</Link>
  }

  if (to?.startsWith('http')) {
    props.target = '_blank'
    props.rel = 'noreferrer nofollow'
  }

  return (
    <a href={to} {...props} css={style}>
      {children}
    </a>
  )
}

const renderImage = ({ src, children, style }) => {
  const props = {}
  const siteData = React.useContext(SiteDataContext)
  const projectCode = siteData?.group?.fieldGroupProjectCode || ''

  if (process.env.STORYBOOK_MEDIA_REPLACE && process.env.STORYBOOK_MEDIA_REPLACE === 'false') {
    return src
  }

  const pathNameReplace = process.env.DRUPAL_MEDIA_PATH || ''
  // eslint-disable-next-line no-useless-escape
  const pathName = src.replace(/^\/sites\/default\/files+\//, 'https://fdn01.fed.be/images/')

  return process.env.DRUPAL_MEDIA_HOSTNAME ? `${pathName}` : src
}

const defaultTransform = {
  a: (node, props, style) => renderLink({ ...node, ...props, ...style}),
  //img: (node, props, style) => renderImage({ ...node, ...props, ...style})
}

export const Html = ({ value = '', transform = {} }) => (
  <StyledContainer>
    {htmr(value, { transform: { ...defaultTransform, ...tableTransform, ...transform }, dangerouslySetChildren: ['style'] })}
  </StyledContainer>
)

export default Html
